import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import LandingPage from './LandingPage';
import VisualCompassPage from './VisualCompassPage';
import StrategyBlueprintPage from './StrategyBlueprintPage';
import EthicalAISimulatorPage from './EthicalAISimulatorPage';
import GovernanceMatrixPage from './GovernanceMatrixPage';
import AIReadinessAssessmentPage from './AIReadinessAssessmentPage';
import UseCaseIdentificationPage from './UseCaseIdentificationPage';
import AIGuruMasterySuite from './AIGuruMasterySuite';
import ContactPage from './pages/ContactPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import ArticlesPage from './pages/ArticlesPage';
import ArticleContent from './pages/ArticleContent';
import ArticlePage from './pages/ArticlePage';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import ScrollToTop from './components/ScrollToTop';


Amplify.configure(awsconfig);


const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { route } = useAuthenticator((context) => [context.route]);
  
  if (route !== 'authenticated') {
    return <Navigate to="/" replace />;
  }
  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <Router>
      <Authenticator.Provider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
	  <Route path="/article/:id" element={<ArticlePage />} />
          <Route
            path="/dashboard"
            element={
              <Authenticator>
                {({ signOut, user }) => (
                  <ProtectedRoute>
                    <AIGuruMasterySuite signOut={signOut} user={user} />
                  </ProtectedRoute>
                )}
              </Authenticator>
            }
          />
          <Route path="/visual-compass" element={<VisualCompassPage />} />
          <Route path="/strategy-blueprint" element={<StrategyBlueprintPage />} />
          <Route path="/ethical-ai-simulator" element={<EthicalAISimulatorPage />} />
          <Route path="/governance-matrix" element={<GovernanceMatrixPage />} />
          <Route path="/ai-readiness-assessment" element={<AIReadinessAssessmentPage />} />
          <Route path="/use-case-identification" element={<UseCaseIdentificationPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        </Routes>
      </Authenticator.Provider>
    </Router>
  );
};

export default App;
